import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { MDXProvider } from "@mdx-js/react";
import Sidebar from "./Sidebar";
import { mainContentId } from "../../buildConstants";
import CodeBlock from "./CodeBlock";

require("../custom.scss");

const MyBlockQuote = props => (
  <blockquote className="blockquote text-center" {...props} />
);

const components = {
  blockquote: MyBlockQuote,
  code: CodeBlock,
};

const BaseLayout = ({ children }) => {
  return (
    <MDXProvider components={components}>
      <a href={`#${mainContentId}`} className="sr-only sr-only-focusable">
        Skip to main content
      </a>
      <div className="container d-flex flex-column h-100">
        <header className="row bg-light">
          <Header useWideLeftColumn={false} />
        </header>
        <div className="row justify-content-between">
          <aside className="d-none d-md-block col-md-2">
            <Sidebar setStickyTop={true} />
          </aside>
          <main className="col-xs-12 col-md-10" id={mainContentId}>
            {children}
          </main>
        </div>
        <footer className="footer mt-auto">
          <Footer />
        </footer>
      </div>
    </MDXProvider>
  );
};

export default BaseLayout;
