exports.siteTitle = "huy.dev";
exports.siteUrl = "https://www.huy.dev";
exports.siteDescription = "Huy Nguyen's Website";

exports.dynamicPostSourceType = "dynamicPost";
exports.staticPageSourceType = "staticPage";

exports.dynamicPostsPath = `${__dirname}/src/posts`;
exports.staticPagesPath = `${__dirname}/src/staticPages`;

// Field names created on MDX nodes manually:
exports.originCreatedFieldName = "origin";
exports.slugCreatedFieldName = "slug";

exports.gatsbyRemarkAutolinkHeadersClassName = "autolink-header-element";

exports.mainContentId = "main-content-74eyep3jgz";
