import React from "react";
import { topLevelRoutes } from "../Utils";
import { Link } from "gatsby";

const Header = ({ useWideLeftColumn }) => {
  const leftColumnSizeClassName = useWideLeftColumn ? "col-md-4" : "col-md-2";
  const rightColumnSizeClassName = useWideLeftColumn ? "col-md-8" : "col-md-10";
  return (
    <>
      <div
        className={`d-none d-md-flex ${leftColumnSizeClassName} justify-content-center align-items-center`}
      >
        <Link to="/" className="navbar-brand m-0">
          huy.dev
        </Link>
      </div>
      <nav
        className={`navbar navbar-light bg-light col-12 ${rightColumnSizeClassName}`}
      >
        <Link to="/" className="d-md-none navbar-brand text-primary">
          huy.dev
        </Link>
        <ul className="navbar-nav flex-row justify-content-end custom-header-nav-container">
          <li className="nav-item mx-1 mx-md-3">
            <Link
              className="nav-link d-none d-md-block"
              activeClassName="active"
              to={`/`}
            >
              Home
            </Link>
          </li>
          <li className="nav-item mx-1 mx-md-3">
            <Link
              className="nav-link"
              activeClassName="active"
              to={`/${topLevelRoutes.about}/`}
            >
              About
            </Link>
          </li>
          <li className="nav-item mx-1 mx-md-3">
            <Link
              className="nav-link"
              activeClassName="active"
              to={`/${topLevelRoutes.works}/`}
            >
              Works
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
