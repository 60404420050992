import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSocialLinkInfo } from "../Utils";

const Icon = ({ icon, href, label }) => (
  <li className="px-1 px-md-2 text-secondary">
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      title={label}
      className="d-block text-secondary"
    >
      <FontAwesomeIcon icon={icon} size="2x" />
    </a>
  </li>
);

const Sidebar = ({ setStickyTop }) => {
  const socialLinkInfo = useSocialLinkInfo();

  let [icons, seticons] = useState(null);
  useEffect(() => {
    const socialLinks = socialLinkInfo.map(({ href, icon, label }) => (
      <Icon href={href} icon={icon} label={label} key={label} />
    ));
    seticons(socialLinks);
  }, [socialLinkInfo]);

  const className = `list-unstyled pt-4 align-items-center d-flex flex-column ${
    setStickyTop ? "sticky-top" : ""
  }`;
  return <ul className={className}>{icons}</ul>;
};

export default Sidebar;
