import Helmet from "react-helmet";
import React, { useState, useEffect, useMemo } from "react";
import {
  faGithub,
  faLinkedin,
  faStackOverflow,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faRss, faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const topLevelRoutes = {
  about: "about",
  works: "works",
};
const facebookAppID = "387942471552541";
export const twitterHandle = "huy_dev";
export const currentYear = 2021;

export const getFullPageUrl = (siteUrl, slug) => {
  const result = `${siteUrl}${slug}`;
  return result;
};
export const emailRevealWait = 500;

export const useSocialLinkInfo = () => {
  const emailAddress = useDelayedEmail();
  const output = useMemo(() => {
    return [
      {
        href: "https://github.com/huy-nguyen",
        icon: faGithub,
        label: "GitHub",
      },
      {
        href: `https://twitter.com/${twitterHandle}`,
        icon: faTwitter,
        label: "Twitter",
      },
      {
        href: "http://www.linkedin.com/in/huynguyen3/",
        icon: faLinkedin,
        label: "LinkedIn",
      },
      {
        href: "https://stackoverflow.com/users/7075699/huy-nguyen",
        icon: faStackOverflow,
        label: "StackOverflow",
      },
      {
        href: "/rss.xml",
        icon: faRss,
        label: "RSS",
      },
      {
        href: `mailto:${emailAddress}`,
        icon: faEnvelope,
        label: "Email",
      },
    ];
  }, [emailAddress]);
  return output;
};

export const useDelayedEmail = () => {
  const [emailAddress, setEmailAddress] = useState("Placeholder");

  useEffect(() => {
    setTimeout(() => {
      const topLevelDomain = "dev";
      const nextLevelDomain = "huy";
      const username = "me";

      let emailAddress = "";
      emailAddress = `.${topLevelDomain}`;
      emailAddress = `${nextLevelDomain}${emailAddress}`;
      emailAddress = `${username}@${emailAddress}`;

      setEmailAddress(emailAddress);
    }, emailRevealWait);
  }, []);

  return emailAddress;
};

export const getMetaTags = ({
  siteUrl,
  slug,
  title,
  summary,
  image = "/ogImageDefault_v1.png",
}) => {
  const imageFullUrl = `${siteUrl}${image}`;
  const fullUrl = getFullPageUrl(siteUrl, slug);
  return (
    <Helmet htmlAttributes={{ lang: "en-US" }}>
      <meta name="description" content={summary} />

      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageFullUrl} />
      <meta property="og:description" content={summary} />
      <meta property="fb:app_id" content={facebookAppID} />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content={twitterHandle} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={summary} />
      <link rel="canonical" href={fullUrl} />

      <title>{title}</title>
    </Helmet>
  );
};
