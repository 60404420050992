// Taken from https://mdxjs.com/guides/syntax-highlighting#all-together
import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import styled from "styled-components";

// Start of line number
// https://github.com/FormidableLabs/prism-react-renderer/issues/45#issuecomment-558472231
const LineNumber = styled.span`
  display: inline-flex;
  justify-content: flex-end;
  width: 1.8rem;
  user-select: none;
  opacity: 0.3;
  padding-right: 0.5rem;
`;
const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
`;
// End of line number

const CodeBlock = ({ children, className }) => {
  const language = className ? className.replace(/language-/, "") : "";

  return (
    <Highlight
      {...defaultProps}
      code={children}
      language={language}
      theme={undefined}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Pre
          className={className}
          style={{ ...style, padding: "0.5rem 0.5rem 0.5rem 0.3rem" }}
        >
          {tokens.map((line, i) => {
            // Remove the last empty line:
            let lineNumberElem;
            if (
              line.length === 1 &&
              line[0].empty === true &&
              i === tokens.length - 1
            ) {
              lineNumberElem = null;
            } else {
              lineNumberElem = <LineNumber>{i + 1}</LineNumber>;
            }

            return (
              <div key={i} {...getLineProps({ line, key: i })}>
                {lineNumberElem}
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            );
          })}
        </Pre>
      )}
    </Highlight>
  );
};

export default CodeBlock;
