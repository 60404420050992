import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSocialLinkInfo } from "../Utils";
import { currentYear } from "../Utils";

const Icon = ({ icon, href, label }) => (
  <li className="px-1 px-md-2 text-secondary d-flex flex-column justify-content-center">
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      title={label}
      className="d-block text-secondary"
    >
      <FontAwesomeIcon icon={icon} size="2x" />
    </a>
  </li>
);

const Footer = () => {
  const socialLinks = useSocialLinkInfo().map(({ href, icon, label }) => (
    <Icon href={href} icon={icon} label={label} key={label} />
  ));

  return (
    <div className="col-12 d-flex bg-light py-2 justify-content-between justify-content-md-end">
      <ul className="d-flex d-md-none m-0 list-unstyled d-flex align-items-center">
        {socialLinks}
      </ul>
      <div className="text-muted d-flex align-items-center">
        <span className="d-none d-md-inline">© {currentYear} Huy Nguyen</span>
        <span className="d-inline d-md-none">© {currentYear}</span>
      </div>
    </div>
  );
};

export default Footer;
